<template>
  <v-container grid-list-md pa-0 relative>
    <v-layout row wrap>
      <v-flex xs12 sm12>
        <v-card outlined class="primary--border">
          <v-card-title class="title">
            Title Wise Due Report Details &nbsp;
            <strong class="pl-2"
              ><u>({{ requestQuery.title }})</u>
            </strong>
            <v-spacer></v-spacer>
            <print-buttons
              :passAuthToReport="true"
              v-if="downloadUrl && form.items.data.length"
              :download-url="downloadUrl"
              :pdf="true"
              :excel="true"
              labelExcel="Download Excel"
              labelPdf="Download Pdf"
            ></print-buttons>
          </v-card-title>
          <v-card outlined>
            <v-card-title class="title mb-0 mt-5">
              <v-flex xs2 sm3>
                <v-calendar-field
                  :disabled="true"
                  id-val="from_date"
                  v-model="frmDate"
                  label="From Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs2 sm3>
                <v-calendar-field
                  :disabled="true"
                  id-val="to_date"
                  v-model="toDate"
                  label="To Date"
                >
                </v-calendar-field>
              </v-flex>
              <v-flex xs3>
                <v-grade-field
                  :disabled="true"
                  :with-all="true"
                  v-model="gradeId"
                ></v-grade-field>
              </v-flex>

              <v-flex xs3>
                <v-section-field
                  :disabled="true"
                  v-model="sectionId"
                  :grade-id="gradeId"
                ></v-section-field>
              </v-flex>
            </v-card-title>
            <div class="data-represent" style="margin-top: -10px">
              <div>
                <span class="d-error"></span> &nbsp;
                <small>
                  <strong>{{ totalSum.currency() }}</strong> Total Due</small
                >
              </div>

              <div>
                <span class="d-primary"></span> &nbsp;
                <small
                  ><strong>{{ requestQuery.title }}</strong>
                </small>
              </div>

              <div>
                <span class="d-info"></span> &nbsp;
                <small
                  >Generated
                  <strong>{{ form.items.data.length }}</strong> time(s)
                </small>
              </div>
            </div>
            <br />
          </v-card>

          <v-data-table
            height="700"
            fixed-header
            fixed-footer
            :headers="headers"
            :items="form.items.data"
            hide-default-footer
            :footer-props="footerProps"
            :server-items-length="form.items.data.length"
            :loading="form.loading"
          >
            <template v-slot:item="{ index, item }">
              <tr style="cursor: pointer;">
                <td>{{ ++index }}</td>
                <td class="text-xs-left">
                  <strong>{{ item.name || "N/A" }}</strong>
                  <small
                    style="color:#777;font-weight:bold;display:block;margin-top:-1px;"
                  >
                    {{ item.enroll_code }} &nbsp;&nbsp;&nbsp;
                  </small>
                </td>
                <td class="text-left">
                  <strong>{{
                    getMonthNameFromString(item.bill_month, true) || "N/A"
                  }}</strong>
                  <small
                    style="color:#777;font-weight:bold;display:block;margin-top:-1px;"
                  >
                    {{ item.sod_date }} &nbsp;&nbsp;&nbsp;
                  </small>
                </td>
                <td class="text-xs-left">
                  {{ item.due_amount.currency() }}
                </td>
              </tr>
            </template>
            <template v-slot:body.append>
              <tr v-if="totalSum > 0">
                <td :colspan="3">
                  <strong>Total:</strong>
                </td>
                <td>
                  <strong>{{ totalSum.currency() }}</strong>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>
<script>
import Form from "@/library/Form";

const NepaliDate = require("nepali-date-js");
const nd = new NepaliDate();
import Mixins from "@/library/Mixins";
import { getMonthNameFromString } from "@/library/helpers";

export default {
  mixins: [Mixins],
  data: () => ({
    form: new Form(
      {
        sod_date: nd.format("YYYY-MM-DD"),
      },
      "/api/report/title-wise-due-report-details"
    ),
    pagination: {
      rowsPerPage: 100,
      descending: true,
    },
    frmDate: "",
    toDate: "",
    gradeId: "all",
    sectionId: "",
    downloadUrl: "",
    totalSum: 0,
    requestQuery: {},
    headers: [
      { text: "#", align: "center", value: "id", width: 50, sortable: false },
      {
        text: "Student Name",
        align: "left",
        value: "fee_head",
        sortable: false,
      },
      {
        text: "Month/Date",
        align: "left",
        value: "fee_head",
        sortable: false,
      },
      {
        text: "Amount",
        align: "left",
        value: "amount",
        width: 200,
        sortable: false,
      },
    ],
  }),
  created() {
    this.requestQuery = this.$route.query;
    this.toDate = this.requestQuery.to_date;
    this.frmDate = this.requestQuery.frm_date;
    this.gradeId = this.requestQuery.gradeId;
    this.sectionId = this.requestQuery.sectionId;
    this.get();
  },

  methods: {
    getMonthNameFromString,
    get() {
      let extraParam = `fromDate=${this.frmDate}&toDate=${this.toDate}&gradeId=${this.gradeId}&sectionId=${this.sectionId}&fee_head_id=${this.requestQuery.fee_head_id}`;
      this.form.get(null, this.queryString(extraParam)).then(({ data }) => {
        this.totalSum = 0;
        data.data.map((res) => {
          this.totalSum += parseFloat(res.due_amount);
        });
        this.pagination.totalItems = data.data.length;
        // this.downloadUrl = data.download_url;
      });
    },
  },
};
</script>
<style lang="scss"></style>
